<!-- 
	This is the sign in page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>
	<div class="sign-in">

		<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle">

			<!-- Sign In Form Column -->
			<a-col :span="24" :md="12" :lg="{span: 12, offset: 0}" :xl="{span: 6, offset: 2}" class="col-form">
				<h1 class="mb-15">Log In</h1>
				<h5 class="font-regular text-muted">Enter account number and password to log in</h5>

				<!-- Sign In Form -->
				<a-form
					id="components-form-demo-normal-login"
					:form="form"
					class="login-form"
					@submit="handleSubmit"
					:hideRequiredMark="true"
				>
					<a-form-item class="mb-10" label="Account No" :colon="false">
						<a-input
						v-decorator="[
						'acc no',
						{ rules: [{ required: true, message: 'Please input a valid account no!', pattern: new RegExp(/[0-9]{10}/),}] },
						]" type="text" placeholder="Acc No" v-model="accountNo"/>
					</a-form-item>
					<a-form-item class="mb-5" label="Password" :colon="false">
						<a-input
						v-decorator="[
						'password',
						{ rules: [{ required: true, message: 'Please input your password!' }] },
						]" type="password" placeholder="Password" v-model="password" />
					</a-form-item>

          <a-form-item class="mb-5" label="OTP" :colon="false" v-if="otpStatus">
            <a-input
                v-decorator="[
						'otp',
						{ rules: [{ required: true, message: 'Please enter otp sent to your email' }] },
						]" type="password" v-model="otp" />
            <a-alert message="We don't recognise your current IP address. An OTP token has been sent to your email, enter it above to continue!" type="info" />
          </a-form-item>
<!--					<a-form-item class="mb-10">-->
<!--    					<a-switch v-model="rememberMe" /> Remember Me-->
<!--					</a-form-item>-->
          <div  v-if="otpStatus && !isLoading">
					<a-form-item>
						<a-button type="primary" block html-type="submit" class="login-form-button" @click="submitOtp()">
							SUBMIT OTP
						</a-button>
					</a-form-item>
          </div>

          <div v-else-if="isLoading" style="text-align: center">
            <a-spin size="large" />
          </div>

          <div v-else>
          <a-form-item>
            <a-button type="primary" block html-type="submit" class="login-form-button" @click="submit()">
              LOG IN
            </a-button>
          </a-form-item>

          </div>
          <a-alert v-if="loginFailed" style="margin-bottom: 15px" :message="failedMessage" type="error" show-icon />
          <div style="text-align: center">
            <a-icon v-if="resendToken" type="reload" theme="outlined" @click="submit()"/> <i v-if="resendToken">Resend Token</i> <i v-if="timer > 0 && otpStatus">Resend Token in {{timer}}</i>
          </div>

        </a-form>
				<!-- / Sign In Form -->

				<p class="font-semibold text-muted">Don't have an account? <router-link to="/create-account" class="font-bold text-dark">Create One</router-link></p>
			</a-col>
			<!-- / Sign In Form Column -->

			<!-- Sign In Image Column -->
			<a-col :span="24" :md="12" :lg="12" :xl="12" class="col-img">
				<img src="images/img-signin.jpg" alt="">
			</a-col>
			<!-- Sign In Image Column -->

		</a-row>
		
	</div>
</template>

<script>
let axios = require("../axios")
	export default ({
		data() {
			return {
				// Binded model property for "Sign In Form" switch button for "Remember Me" .
				rememberMe: true,
        password: "",
        accountNo: "",
        otp: "",
        otpStatus: false,
        // account: ""
        tokens: {},
        user: {},
        isLoading: false,
        loginFailed: false,
        failedMessage: "Failed",
        resendToken: false,
        timer: 60,
        runCountDown: false
			}
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'normal_login' });
		},
		methods: {
			// Handles input validation after submission.
			handleSubmit(e) {
				e.preventDefault();
				this.form.validateFields((err, values) => {
					if ( !err ) {
						console.log('Received values of form: ', values) ;
					}
				});
			},
      async submit(){
          let data = {
            "username": this.accountNo,
            "password": this.password
          }
         try {
           if (this.accountNo && this.password) {
             this.isLoading = true;
             this.loginFailed = false;
           const response = await axios.post("/account/login/", data
           );
           console.log(response)
           this.tokens = response.data.tokens
             this.user = {email: response.data.email,
               first_name: response.data.first_name,
               last_name: response.data.last_name,
               accountNo: response.data.username,
                card_no: response.data.card_no,
                venmo: response.data.venmo,
                paypal: response.data.paypal}
             if (response.data.tokens && !response.data.otp){
               this.isLoading = false;
               this.otpStatus = true;
               this.resendToken = false
               if (this.runCountDown){
                 this.timer = 180;
               }else{
                 this.timer = 60;
               }
               if(!this.runCountDown) {
                 this.countDown()
               }
             }else if (response.data.tokens && response.data.otp){
               localStorage.setItem("token", this.tokens.access);
               localStorage.setItem("userLoggedIn", "true");
               this.$store.commit("SET_USER", this.user);
                console.log(this.$store.state.user)
               await this.$router.push("/overview")
             }
         }
         }catch (e) {
           console.log(e.response)
           if(e.response.data.detail == "Invalid credentials, try again"){
           this.failedMessage = "Invalid Credentials!"
           }else if (e.response.data.detail == "Your account has been blocked!"){
           this.failedMessage = "Account Suspended, Contact Support."
           }
           this.loginFailed = true;
           this.isLoading = false;
         }
      },

      countDown(){
			  this.runCountDown = true;
        window.setInterval(() => {
          // if (this.timer >= 1) {
            this.timer -= 1

          if(this.timer === 0){
            this.resendToken = true
          }
        }, 1000);
      },

      async submitOtp(){
        const authe = {
          headers: { Authorization: "Bearer " + this.tokens.access },
        };

        const otpData = {
          "otp_code": this.otp
        }
        try {
          this.isLoading = true;
          this.loginFailed = false;
          const responseOtp = await axios.post("/account/otp/", otpData, authe);
          console.log(responseOtp)
          if (responseOtp.data.otp){
            localStorage.setItem("token", this.tokens.access);
            localStorage.setItem("userLoggedIn", "true");
            this.$store.commit("SET_USER", this.user);
            console.log(this.$store.state.user)
            await this.$router.push("/overview")
          }else{
            console.log(responseOtp)
            this.isLoading = false;
            this.failedMessage = "Invalid OTP entered!"
            this.loginFailed = true;
            this.isLoading = false;
          }
        }catch (e) {
          console.log(e.response)
          this.isLoading = false;
          this.failedMessage = "Invalid OTP entered!"
          this.loginFailed = true;
          this.isLoading = false;
        }
      }
		},
	})

</script>

<style lang="scss">
	body {
		background-color: #ffffff;
	}
</style>